import { breakpoints } from '../../../styles/breakpoints';
import { Box, extraBreakpoint } from '../../../styles/basicStyles';
import styled, { css } from 'styled-components';

export const TitleWrapper = styled(Box)`
  position: relative;
  z-index: 4;

  @media (max-width: ${extraBreakpoint}rem) {
    & .text {
      font-size: 1.375rem;
      line-height: 1.8rem;
    }
  }
`;

export const Line = styled.div<{ started: boolean }>`
  position: absolute;
  left: 0;
  bottom: -1.25rem;
  width: 100%;

  & svg {
    width: 100%;
    height: auto;
  }

  ${({ started }) => started ?
    css`
      display: block; 

      & path {
        stroke-dasharray: 500;
        stroke-dashoffset: 500;
        animation-duration: 2.8s;
        animation-name: dash;
        animation-iteration-count: 1;
        animation-delay: 0;
        animation-fill-mode: forwards;
      }

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    `
    :
    css`
      display: none;
    `
  }

  @media ${breakpoints.md} {
    bottom: -0.75rem;
  }

  @media (max-width: ${extraBreakpoint}rem) {
    bottom: -0.5rem;
  }
`;

export const Circle1 = styled.div`
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 13.75rem;
  height: 13.75rem;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #000 0%, #201658 100%, #000 100%);
  filter: blur(9.375rem);
  z-index: 1;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;

  @media ${breakpoints.md} {
    bottom: -6.875rem;
    left: -5rem;
  }
`;

export const Circle2 = styled.div`
  position: absolute;
  top: -5rem;
  right: -3.5rem;
  width: 16.25rem;
  height: 16.25rem;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(100, 255, 227, 0.90) 0%, rgba(100, 255, 227, 0.90) 100%);
  filter: blur(9.375rem);
  z-index: 1;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;

  @media ${breakpoints.md} {
    right: -1rem;
    top: -2.5rem;
    width: 11.875rem;
    height: 11.875rem;
    background: radial-gradient(50% 50% at 50% 50%, rgba(100, 255, 227, 0.50) 0%, rgba(100, 255, 227, 0.50) 100%);
    filter: blur(4.688rem);
    // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;
  }
`;
