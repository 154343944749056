import { Block, Box, EllipsisText, Image } from "../../../styles/basicStyles";
import { colors } from "../../../styles/colors";
import { FC, useEffect, useRef, useState } from "react";
import { CardWrapper, ImageWrapper, SeparatorDot } from "./styles";
import { isMobile } from 'react-device-detect';
import Typography from "../../typography";
import dayjs from "dayjs";
import Icon from "../../icon";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "react-reveal/Fade";
import useLazyBgImage from "../../../hooks/images/useLazyBgImage";

interface Props {
  imagePath: string;
  title: string;
  subtitle: string;
  tags?: string[];
  author?: string;
  date?: Date;
  onClick: () => void;
}

const Card: FC<Props> = ({ imagePath, title, subtitle, tags, author, date, onClick }) => {
  const [started, setStarted] = useState<boolean>(false);
  const bgRef = useLazyBgImage([imagePath]);
  
  const checkState = (isVisible: boolean) => {
    if(isVisible && !started) setStarted(true);
  };

  return (
    <VisibilitySensor onChange={checkState} partialVisibility delayedCall>
      <CardWrapper
        fDirection="column"
        fJustify="space-between"
        h='100%'
        cursor="pointer"
        onClick={onClick}
      >
        <Block position="relative" overflow='hidden' w='100%'>
          <ImageWrapper ref={(el) => (bgRef.current[0] = el)} started={started} className="image">
            <Image src='/assets/fill.gif' w='100%' h='auto' alt='image to fill empty space' />
          </ImageWrapper>
          <Box 
            fDirection="column" 
            bgColor={colors.white}
            position='absolute'
            bottom={0}
            w='90%'
            bTopRightRadius={1}
            pt={1.25}
            pl={1.25}
          >
            <Fade bottom>
              <Box fDirection="column" w="auto">
                <EllipsisText lines={1} ellipsisColor={colors.coolGrey}>
                  <Typography
                    opacity={0.7}
                    fDirections="row"
                    fSize={1.063}
                    lHeight={1.25}
                    fWeight={400}
                    style={{ color: colors.black }}
                  >
                    {title}
                  </Typography>
                </EllipsisText>
                <Block w="100%" bgColor={colors.purple} h={0.188} />
              </Box>
            </Fade>
          </Box>
        </Block>
        <Box
          fJustify="space-between"
          fAlign="center"
          bgColor={colors.white}
        >
          <Fade bottom cascade>
            <Box
              fDirection="column"
              pl={1.25}
              pr={0.875}
              pt={0.625}
              pb={1}
            >
              <EllipsisText lines={2} minH={{ sm: 3.75, xxs: 3 }}>
                <Typography
                  as='h2'
                  fSize={{ md: 1.438, sm: 1.25, xxs: 1.125 }}
                  lHeight={{ sm: 1.875, xxs: 1.5 }}
                  fWeight={700}
                  style={{ color: colors.black }}
                >
                  {subtitle}
                </Typography>
              </EllipsisText>
              {
                tags && tags?.length > 0 &&
                <Box fWrap="wrap">
                  {
                    tags.map((tag: string, index: number) =>
                      <Typography
                        key={index}
                        mt={0.5}
                        pr={1.375}
                        fDirections="row"
                        fSize={1.125}
                        lHeight={1.5}
                        fWeight={400}
                        color='#575768'
                        wSpace="nowrap"
                        tTransform='uppercase'
                      >
                        {tag}
                      </Typography>
                    )
                  }
                </Box>
              }
              {
                (author || date) &&
                <Block mt={1.25}>
                  <Typography
                    fSize={1}
                    lHeight={1.125}
                    fWeight={400}
                    color='#575768'
                    wSpace="nowrap"
                  >
                    {author && author}
                    {author && date ? <SeparatorDot /> : null}
                    {date && dayjs(date).format("D MMM YYYY")}
                  </Typography>
                </Block>
              }
            </Box>
          </Fade>
          {
            !isMobile &&
            <Box
              fJustify="flex-end"
              pr={0.5}
              className="card-arrow"
              w="auto"
            >
              <Icon icon="outlined_arrowRight" className="icon" size={1.5} />
            </Box>
          }
        </Box>
        <Block h={0.063} w="100%" bgColor={colors.softPeach} />
      </CardWrapper>
    </VisibilitySensor>
  );
};

export default Card;
