import { colors } from "../../../styles/colors";
import { useTranslation } from "next-i18next";
import { FC, useState } from "react";
import { Block, Box } from "../../../styles/basicStyles";
import { useRouter } from "next/router";
import { Circle1, Circle2, Line, TitleWrapper } from "./styles";
import Typography from "../../typography";
import Button from "../../button";
import VisibilitySensor from "react-visibility-sensor";

const ContactBanner: FC = () => {
  const [started, setStarted] = useState<boolean>(false);
  const router = useRouter();
  const { t } = useTranslation();

  const checkState = (isVisible: boolean) => {
    if(isVisible && !started) setStarted(true);
  };

  return (
    <VisibilitySensor onChange={checkState} delayedCall>
      <Box
        bgColor='#705BEE'
        mb={{ md: 6.313, xxs: 0 }}
        bRadius={{ md: 1.25, xxs: 0 }}
        pt={4.75}
        pb={{ sm: 4.75, xxs: 4.063 }}
        pr={0.5}
        pl={0.5}
        position='relative'
        overflow='hidden'
      >
        <Circle1 />
        <Circle2 />
        <Box fJustify="center" fAlign="center" fDirection="column" position='relative' zIndex={2}>
          <TitleWrapper fDirection='column' fAlign='center' maxW={35}>
            <Typography
              as='div'
              tAlign='center'
              fSize={{ md: 2.813, xxs: 1.688 }}
              lHeight={{ md: 3.5, xxs: 2.375 }}
              fWeight={700}
              color={colors.white}
              className='text'
            >
              {t('READY_TO_CREATE_SOMETHING')}&nbsp;
            </Typography>
            <Box fJustify='center' fAlign='center'>
              <Block position='relative'>
                <Typography
                  fSize={{ md: 2.813, xxs: 1.688 }}
                  lHeight={{ md: 3.5, xxs: 2.375 }}
                  fWeight={700}
                  color={colors.white}
                  className='text'
                >
                  {t('AMAZING')}
                </Typography>
                <Line started={started}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="179" height="16" viewBox="0 0 179 16" fill="none">
                    <path d="M2.24617 8.86454C16.481 5.40518 31.7896 3.73998 46.4268 3.22884C49.069 3.13657 54.0602 1.98437 56.5854 3.29096C60.8401 5.49242 47.345 10.9237 51.126 12.9938C52.6331 13.8189 55.9928 13.1971 57.6077 13.0327C62.7396 12.5102 67.9196 11.605 73.0319 10.8645C85.2329 9.09721 97.3386 7.59028 109.663 7.15992C110.8 7.12021 115.219 6.20155 115.604 8.01354C115.999 9.86792 110.776 10.9114 109.427 11.261C108.701 11.4491 104.708 12.5351 104.648 13.3228C104.626 13.6126 110.432 13.1828 110.856 13.106C119.44 11.5497 128.694 11.7639 137.41 11.686C150.587 11.5683 163.826 11.9498 176.996 12.3122" stroke="#64FFE3" strokeWidth="4" strokeLinecap="round"/>
                  </svg>
                </Line>
              </Block>
              <Typography
                fSize={{ md: 2.813, xxs: 1.688 }}
                lHeight={{ md: 3.5, xxs: 2.375 }}
                fWeight={700}
                color={colors.white}
                className='text'
              >
                &nbsp;{t('TOGETHER')}?
              </Typography>
            </Box>
          </TitleWrapper>
          <Button
            mt={{ sm: 2.188, xxs: 3 }}
            text={t("LETS_TALK")}
            size="md"
            variant="primary"
            textColor={colors.white}
            bgColor={colors.black}
            icon="outlined_arrowRight"
            onClick={() => router.push("/start-project")}
          />
        </Box>
      </Box>
    </VisibilitySensor>
  );
};

export default ContactBanner;
