import { useEffect, useRef } from 'react';

const useLazyBgImage = (imagePaths: (string | undefined)[], threshold: number = 0.1) => {
  const refs = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting && entry.target instanceof HTMLElement) {
            const index = refs.current.indexOf(entry.target);
            if(index !== -1 && imagePaths[index]) {
              entry.target.style.backgroundImage = `url(${imagePaths[index]})`;
              observer.unobserve(entry.target);
            }
          }
        });
      },
      { threshold }
    );

    refs.current.forEach((ref) => {
      if(ref) observer.observe(ref);
    });

    return () => {
      refs.current.forEach((ref) => {
        if(ref) observer.unobserve(ref);
      });
    };
  }, [imagePaths, threshold]);

  return refs;
};

export default useLazyBgImage;