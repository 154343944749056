import { Box, transitionsDelay } from '../../../styles/basicStyles';
import styled from 'styled-components';

export const CardWrapper = styled(Box)`
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  background-color: #ffffff;
  
  & .image {
    transition: transform ${transitionsDelay} linear;
  }

  & .card-arrow {
    opacity: 0;
    transition: opacity ${transitionsDelay} linear;
  }

  & .react-reveal {
    width: auto !important;
  }

  &:hover {
    & .card-arrow {
      opacity: 1;
    }
    
    & .image {
      transform: scale(1.05);
    }
  }
`;

export const SeparatorDot = styled.span`
  height: 0.313rem;
  width: 0.313rem;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  background-color: ${({ theme }) => theme.colors.coolGrey}; 
`;

export const ImageWrapper = styled.div<{ started: boolean }>`
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-bottom-right-radius: 0.25rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    transform: scaleY(${({ started }) => started ? 0 : 1});
    transform-origin: top;
    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1);
  }
`;
